.home-container {
    text-align: center;
    padding: 2%;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;
    margin: 0 auto;
    justify-content: center;
    max-width: 90%;
}

.grid-item {
    position: relative;
    overflow: hidden;
}

.grid-item img {
    width: 100%;
    height: auto;
    border-radius: 1vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.2);
}

.grid-item:hover {
    transform: scale(1.05);
    opacity: 0.8;
}

p {
    max-width: 90%; /* Align with the max-width of the image grid */
    margin: 0 auto; /* Center the text within the container */
    text-align: center; /* Optional: Justify the text for a clean appearance */
    line-height: 1.4; /* Keep the line height consistent */
}


/* RENDERS IN CENTER OF IMAGES */

/* .grid-item .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    pointer-events: none;
    width: 100%;
    text-align: center;
    z-index: 2;
} */

/* RENDERS AT BOTTOM OF IMAGES */

/* .grid-item .overlay-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    pointer-events: none;
    text-align: center;
    width: 100%;
    z-index: 2;
} */

/* RENDERS AT BOTTOM OF IMAGES - ONLY ON HOVER */

.grid-item .overlay-text {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    pointer-events: none;
    text-align: center;
    width: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.grid-item:hover .overlay-text {
    opacity: 1;
}