.ma-container {
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}

.ma-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin-bottom: 20px;
}

.ma-text {
    font-size: 1.5rem;
    color: white;
}

/* Responsive adjustments */
@media (max-width: 1023px) {
    .ma-container {
        padding: 15px;
    }

    .ma-image {
        max-width: 400px;
    }

    .ma-text {
        font-size: 1.25rem;
    }
}

@media (max-width: 767px) {
    .ma-container {
        padding: 10px;
    }

    .ma-image {
        max-width: 300px;
    }

    .ma-text {
        font-size: 1rem;
    }
}
