/* Base styles for the Navbar */
.navbar {
    background-color: #222;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid #333;
}

/* Styles for Navbar Links */
.navbar-links {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navbar-links a {
    color: #9e9e9e;
    text-align: left;
    padding: 14px 20px;
    text-decoration: none;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.navbar-links a:hover {
    color: #088F8F;
}

.navbar-links.active {
    color: #088F8F;
    /* font-weight: bold; */
}

/* Hamburger for mobile and tablet view */
.hamburger {
    display: none;
    font-size: 34px;
    cursor: pointer;
    color: #9e9e9e;
    padding-right: 20px;
}

/* Styles for Logo */
.logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.logo img {
    height: 35px;
    margin: 0;
    margin-right: 10px;
}

.logo div {
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo p {
    font-size: 18px;
    color: white;
}

/* Dropdown styles */
.dropdown {
    display: none;
    position: relative;
    align-items: center;
}

.dropdown-content {
    background-color: #222;
    opacity: 1;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1001;
    font-size: 0.9rem;
    line-height: 1.25rem;
}

.dropdown-content a {
    color: #9e9e9e;
    padding: 14px 20px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    color: #088F8F;
}

.dropdown .show {
    display: flex;
}

/* Tablet and mobile view */
@media (max-width: 1023px) {
    .navbar {
        height: 60px;
        width: 100%;
        flex-direction: row;
    }

    .navbar-links {
        display: none;
    }

    .dropdown {
        display: flex;
        justify-content: space-between;
        align-items: right;
        width: 100%;
    }

    .hamburger {
        display: block;
    }

    .dropdown-content {
        flex-direction: column;
        position: fixed;
        top: 60px;
        right: 0;
        width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        z-index: 1001;
    }

    .dropdown-content.show {
        display: flex;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    .logo {
        padding: 0;
    }

    .logo img {
        height: 50px;
        display: block;
        margin: 5px;
    }
}
