/* Base styles */
.App {
  text-align: center;
  background-color: #222;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

/* Desktop view (with sidebar) */
@media (min-width: 1024px) {
  .App-header {
    margin-left: 260px;
    width: calc(100% - 260px); /* Adjust width to account for sidebar */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: white;
  }

  .App-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}

/* Tablet view */
@media (min-width: 768px) and (max-width: 1023px) {
  .App-header {
    width: 100%;
    margin: auto;
  }

  .App-content {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-logo {
    max-width: 250px;
  }
}

/* Mobile view */
@media (max-width: 767px) {
  .App-header {
    width: 100%;
    margin: auto;
  }

  .App-content {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .App-logo {
    max-width: 200px;
  }
}
